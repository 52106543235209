<template>
  <v-card>
    <v-card-title>
      <!-- <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            small
            absolute
            @click="dialog = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn> -->
      {{ isEdit ? "Editar banner" : "Crear Nuevo banner" }}
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text>
      <v-form class="mt-6" ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              style="border-radius: 10px"
              label="Comienza"
              filled
              v-model="newBanner.dateStart"
              type="datetime-local"
              rounded
              required
              :rules="requerid"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              style="border-radius: 10px"
              label="Termina"
              filled
              v-model="newBanner.dateEnd"
              :rules="requerid"
              required
              type="datetime-local"
              rounded
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card-subtitle class="mt-6">
          Configuración de target
        </v-card-subtitle>
        <v-divider> </v-divider>

        <v-row>
          <v-col cols="6" class="mt-6">
            <v-img
              height="200px"
              width="100%"
              :src="imgPreview || imagedefaul"
              style="border-radius: 10px"
            >
            </v-img>
            <v-file-input
              accept="image/*"
              v-model="fileImg"
              filled
              rounded
              prepend-icon="far fa-file-image"
              style="border-radius: 10px"
              placeholder="Subir imagen"
              chips
              class="mt-6"
              @change="uploadImg"
              label="Subir imagen"
            ></v-file-input>
          </v-col>
          <v-col cols="6" class="mt-6">
            <v-select
              label="Escoge el tipo de ítem"
              :items="type"
              v-model="target.type"
              item-text="name"
              :rules="requerid"
              required
              @change="setType"
              item-value="value"
              style="border-radius: 10px"
              filled
              rounded
            ></v-select>
            <v-select
              v-if="target.type != 'url' && target.type != 'event'"
              :items="categories"
              :loading="loading"
              label="Escoge una categoría"
              v-model="target.category"
              class="select-city mt-6"
              @change="getBusinesses"
              item-text="name"
              item-value=".key"
              style="border-radius: 10px"
              filled
              rounded
            ></v-select>
            <v-select
              v-if="
                target.type == 'business' ||
                target.type == 'product' ||
                target.type == 'event'
              "
              :items="businesses"
              v-model="target.business"
              @change="getProducts"
              item-text="shortName"
              item-value=".key"
              label="Selecciona un comercio"
              style="border-radius: 10px"
              filled
              rounded
            ></v-select>

            <v-select
              v-if="target.type == 'event'"
              :items="event"
              v-model="target.event"
              item-text="name"
              @change="getEvent"
              :item-value="[`.key`]"
              style="border-radius: 10px"
              filled
              rounded
            ></v-select>
            <v-select
              v-if="target.type == 'product'"
              :disabled="!target.business"
              label="Selecciona un producto"
              :items="sortedArrayProducts"
              v-model="target.product"
              class="select-city mt-6"
              item-text="name"
              @change="productChanged"
              item-value=".key"
              style="border-radius: 10px"
              filled
              rounded
            ></v-select>
            <v-text-field
              v-if="target.type == 'url'"
              style="border-radius: 10px"
              label="url"
              filled
              v-model="newBanner.url"
              :rules="requerid"
              required
              type="text"
              rounded
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions class="actions-btn">
      <v-switch dense label="Activo" v-model="newBanner.active"></v-switch>

      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        :loading="btnloading"
        style="border-radius: 10px"
        @click="$emit('closed')"
        >cancelar
      </v-btn>
      <v-btn
        :loading="btnloading"
        v-if="isEdit"
        color="primary"
        style="border-radius: 10px"
        @click="updateBanner"
        :disabled="!valid"
        >Guardar</v-btn
      >
      <v-btn
        v-else
        :loading="btnloading"
        color="primary"
        style="border-radius: 10px"
        @click="save"
        :disabled="!valid"
      >
        Aceptar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { LMap, LTileLayer, LIcon, LMarker, LTooltip } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
var randomstring = require("randomstring");
import { OpenStreetMapProvider } from "leaflet-geosearch";
import VGeosearch from "vue2-leaflet-geosearch";
import { mapActions, mapState } from "vuex";
import { db, fb } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
import { doc } from "@firebase/firestore";
moment.locale("es");
export default {
  name: "formNewEvent",

  components: {
    LMap,
    LTileLayer,
    LIcon,
    LMarker,
    LTooltip,
    VGeosearch,
  },
  props: ["selectedCity", "selectedCountry", "dataEdit"],
  data() {
    return {
      isEdit: false,
      imgPreview: null,
      cities: [],
      products: [],
      target: [],
      target: {},
      businesses: [],
      countries: [],
      categories: [],
      event: [],
      fileImg: null,
      valid: true,
      type: [
        { name: "Comercio", value: "business" },
        { name: "Producto", value: "product" },
        { name: "Evento", value: "event" },
        { name: "URL", value: "url" },
      ],
      imagedefaul: require("@/assets/templateImage.svg"),

      loading: true,
      btnloading: false,

      hoy: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu1: false,
      File: null,
      menu2: false,
      requerid: [(v) => !!v || "Campo requerido"],

      newBanner: {},
    };
  },
  computed: {
    ...mapState(["business", "user", "selectedEvent"]),
    sortedArrayProducts() {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      return this.products.sort(compare);
    },
  },
  methods: {
    ...mapActions([
      "_searchTerm",
      "Alert_",
      "addEventSelection",
      "visibleSearch",
    ]),

    async getEvent(event) {
      this.imgPreview =
        this.target.type == "event"
          ? await this.event.find((item) => item[`.key`] == event).cover
              .original
          : "";
      this.target.cover.original = this.imgPreview;
      this.target.cover.loading = this.imgPreview;
    },
    setType(type) {
     // console.debug(type);

      if (type == "event" || "business") {
        this.$binding(
          "businesses",
          db
            .collection("businesses")
            .where("manageEvents", "==", true)
            .where("active", "==", true)
        );
       // console.debug(this.businesses);
      }
    },
    async getProducts(business) {
      if (this.target.type == "business") {
        this.imgPreview =
          this.target.type == "business"
            ? this.businesses.find((item) => item[".key"] == business).cover
                .original
            : "";
      }
      if (this.target.type == "event") {
       // console.debug(business);
        await this.$binding(
          "event",
          db.collection("events").where("business", "==", business)
        );
      }
      this.$binding(
        "products",
        db
          .collection("products")
          .where("business", "array-contains", business)
          .where("deleted", "==", false)
          .where("addon", "==", false)
      );
    },
    getBusinesses(category) {
      // this.target.product = "";
      // this.target.business = "";
      this.$binding(
        "businesses",
        db
          .collection("businesses")
          .where("ochoCategories", "array-contains", category)
      );
    },
    updateBanner() {
      this.btnloading = true;

      // let token = await this.getSectionToken;
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        const obj = {
          url: this.newBanner.url ? this.newBanner.url : "",
          deleted: false,
          active: this.newBanner.active,
          dateEnd: fb.firestore.Timestamp.fromDate(
            new Date(this.newBanner.dateEnd)
          ),
          dateStart: fb.firestore.Timestamp.fromDate(
            new Date(this.newBanner.dateStart)
          ),
          city: this.selectedCity,
          target: this.target,
          cover: {
            loading: this.fileImg ? '': this.imgPreview,
            original: this.fileImg ? '': this.imgPreview,
          },
          country: this.selectedCountry,
          modifiedAt: new Date(),
          modifiedBy: this.user[`.key`],
        };
       // console.debug(obj);
       // console.debug(this.dataEdit[`.key`]);

        db.collection("eventsBanners")
          .doc(this.dataEdit[`.key`])
          .update(obj)
          .then(async (ref) => {
            if (this.fileImg) {
              await this.uploadImage(this.dataEdit[`.key`]);
            }
            this.Alert_({
              text: "Banner actualizado correctamente",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "success",
            });
            this.btnloading = false;
            setTimeout(() => {
              this.$emit("closed");
            }, 2000);
          })
          .catch((error) => {
           // console.debug(error);
            this.Alert_({
              text: "Ocurrió un error al actualizar el banner",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });
            this.btnloading = false;
          });
      }
    },
    save() {
      this.btnloading = true;

      // let token = await this.getSectionToken;
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        const obj = {
          url: this.newBanner.url ? this.newBanner.url : "",
          deleted: false,
          active: this.newBanner.active,
          dateEnd: fb.firestore.Timestamp.fromDate(
            new Date(this.newBanner.dateEnd)
          ),
          dateStart: fb.firestore.Timestamp.fromDate(
            new Date(this.newBanner.dateStart)
          ),
          city: this.selectedCity,
          target: this.target,
          cover: {
            loading: this.fileImg ? '': this.imgPreview,
            original: this.fileImg ? '': this.imgPreview,
          },
          createdAt: new Date(),
          createdBy: this.user[`.key`],
          country: this.selectedCountry,
        };
       // console.debug(obj);

        db.collection("eventsBanners")
          .add(obj)
          .then(async (ref) => {
           // console.debug(ref);
            if (this.fileImg) {
              await this.uploadImage(ref.id);
            }
            this.Alert_({
              text: "Banner creado correctamente",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "success",
            });
            setTimeout(() => {
              this.$emit("closed");
            }, 2000);
          })
          .catch((error) => {
           // console.debug(error);
            this.Alert_({
              text: "Ocurrió un error al crear el Evento",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });
            this.btnloading = false;
          });
      } else {
        this.Alert_({
          text: "LLena todos los campos",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "error",
        });
        this.btnloading = false;
      }
    },
    uploadImage(id) {
// console.debug( this.fileImg.name)
      return new Promise((resolve, reject) => {
        fb.app()
          .storage(`${process.env.VUE_APP_EL_OCHO_BK}-vive-banners/`)
          .ref()
          .child(`${id}/${this.fileImg.name}`)
          .put(this.fileImg)
          .then((res) => {
            resolve("success");
          })
          .catch((err) => {
            //console.log(err);
            reject(err);
          });
      });
    },
    getCities(countryId) {
      this.categories = [];
      this.cities = [];

      this.selectedCity = "";

      db.collection("cities")
        .where("deleted", "==", false)
        .where("countryId", "==", countryId)
        .get()
        .then((response) => {
          response.forEach((item) => {
            let city = item.data();
            city[".key"] = item.id;
            this.cities.push(city);
          });
        });
    },
    generateCode() {
      if (this.newBanner.name) {
        return `${this.newBanner.name.toUpperCase().substr(0, 4)}-${randomstring
          .generate(8)
          .toUpperCase()}`;
      } else {
        return `SEC-${randomstring.generate(6).toUpperCase()}`;
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    uploadImg(e) {
      // this.imgPreview = URL.createObjectURL(e);
      var reader = new FileReader();

      reader.onload = (e) => {
        this.imgPreview = e.target.result;
      };
      this.imgPreview = reader.readAsDataURL(e);
    },
    productChanged(product) {
      this.imgPreview =
        this.products.find((item) => item[".key"] == this.target.product).images
          .length > 0
          ? this.products.find((item) => item[".key"] == product).images[0]
          : "";
    },
  },
  async mounted() {
    let countries = this.$binding(
      "countries",
      db
        .collection("countries")
        .where("active", "==", true)
        .where("deleted", "==", false)
    );
    let categories = this.$binding(
      "categories",
      db
        .collection("categories")
        .where("deleted", "==", false)
        .where("city", "==", this.selectedCity)
    );
    if (this.dataEdit) {
      this.isEdit = true;
      this.newBanner = Object.assign({}, this.dataEdit);
      this.target = Object.assign({}, this.dataEdit.target);
      var targetValue = {
        event: () => {
          this.setType("event");
          this.getProducts(this.target.business);
        },
        business: () => {
          this.getBusinesses(this.target.category);
        },
        product: () => {
          this.getProducts(this.target.business);
          this.getBusinesses(this.target.category);
        },
        url: () => {},
      };
      targetValue[this.target.type]();
      this.newBanner.dateStart = moment( this.dataEdit.dateStart.toDate()
      ).format("YYYY-MM-DDTkk:mm");
      this.newBanner.dateEnd = moment(this.dataEdit.dateEnd.toDate()).format(
        "YYYY-MM-DDTkk:mm"
      );
      this.imgPreview = this.newBanner.cover.original;
     // console.debug(this.newBanner);
    }
    await Promise.all([countries, categories]);

    this.loading = false;
  },
};
</script>
<style lang="scss">
.theme--dark.v-tabs-items {
  background-color: #1e1e1e00 !important;
}

.upload {
  display: none;
}

.img-tag {
  background-color: rgba(231, 231, 230, 0.808) !important;
  justify-content: center;
  font-weight: bold;
  color: #000 !important;
}
</style>

<template>
  <div class="General">
    <!-- header de titulo y acciones -->
    <headerLayaut>
      <v-row>
        <v-col cols="12" md="6">
          <v-select
            v-model="selectedCountry"
            :items="countries"
            placeholder="Escoge el pais"
            filled
            :loading="loading"
            style="border-radius: 10px"
            @change="getCities"
            item-text="name"
            item-value=".key"
            rounded
          />
        </v-col>

        <v-col cols="12" md="6">
          <v-select
            v-model="selectedCity"
            :items="cities"
            placeholder="Escoge la ciudad"
            filled
            :loading="loading"
            style="border-radius: 10px"
            :disabled="!selectedCountry"
            @change="getBanners"
            item-text="name"
            item-value=".key"
            rounded
            class="mr-4"
          />
        </v-col>
      </v-row>
      <v-btn
        :disabled="!selectedCountry"
        color="primary"
        style="border-radius: 10px"
        class="mr-2"
        @click="openSort"
        >Ordenar</v-btn
      >
      <v-btn
        color="primary"
        :disabled="!selectedCity"
        style="border-radius: 10px"
        class="mr-2"
        @click="dialog = true"
        >Crear</v-btn
      >
    </headerLayaut>
    <!-- contenido de la pagina -->

    <v-row class="body-template">
      <v-col
        class="animations"
        cols="12"
        :md="showMobile ? '12' : '11'"
        :lg="showMobile ? '12' : '11'"
        :sm="showMobile ? '12' : '11'"
      >
        <v-data-table
          :headers="headers"
          :items="allBanners"
          fixed-header
          sort-by="position"
          sort-asc
          :search="$store.state.search"
          :calculate-widths="true"
          :loading="loading"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;

                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>
          <template v-slot:[`item.cover`]="{ item }">
            <v-img
              v-if="item.cover"
              :lazy-src="item.cover.original"
              :src="item.cover.original"
              max-height="50"
              max-width="75"
            ></v-img>
          </template>
          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              dense
              v-model="item.active"
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  'active'
                )
              "
            ></v-switch>
          </template>
          <template v-slot:[`item.isTester`]="{ item }">
            <v-switch
              dense
              v-model="item.isTester"
              @change="
                switchControlChanged(
                  { isTester: item.isTester },
                  item['.key'],
                  'isTester'
                )
              "
            ></v-switch>
          </template>
          <template v-slot:[`item.dateStart`]="{ item }">
            {{ item.dateStart | tsToDate }}
          </template>
          <template v-slot:[`item.dateEnd`]="{ item }">
            {{ item.dateEnd | tsToDate }}
          </template>
          <template v-slot:[`item.options`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mx-1"
                  fab
                  v-on="on"
                  dark
                  style="border-radius: 10px"
                  small
                  @click="openEdit(item)"
                  :elevation="0"
                  color="info"
                >
                  <v-icon small dark>fa-solid fa-pen</v-icon>
                </v-btn>
                <v-btn
                  class="mx-1"
                  fab
                  v-on="on"
                  dark
                  style="border-radius: 10px"
                  small
                  @click="openDialogDeleted(item)"
                  :elevation="0"
                  color="error"
                >
                  <v-icon small dark>fa-solid fa-trash</v-icon>
                </v-btn>
              </template>
              <span>Editar banner </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <v-col
        cols="12"
        :md="showMobile ? '3' : '1'"
        :lg="showMobile ? '3' : '1'"
        :sm="showMobile ? '3' : '1'"
        class="flex-center"
      >
        <transition name="slide-fade">
          <div class="wrap-mobile" v-if="showMobile && selectBanner">
            <div class="display">
              <svg class="docs-demo-device__ios-notch" viewBox="0 0 219 31">
                <path
                  d="M0 1V0h219v1a5 5 0 0 0-5 5v3c0 12.15-9.85 22-22 22H27C14.85 31 5 21.15 5 9V6a5 5 0 0 0-5-5z"
                  fill-rule="evenodd"
                ></path>
              </svg>

              <img
                src="@/assets/header-02.svg"
                class="header-app"
                alt=""
                srcset=""
              />
              <span v-if="nameCity.name" class="name-city">
                {{ nameCity.name }}</span
              >

              <v-img
                height="160px"
                :lazy-src="selectBanner.original"
                :src="selectBanner.original"
              >
                <div class="btn-wrap">
                  <div
                    :class="{
                      'btn-actions': true,
                      'btn-actions-active': item.cover == selectBanner,
                    }"
                    v-for="(item, i) in sortedBanners"
                    :key="i"
                    @click="selectBanner = item.cover"
                  ></div>
                </div>
              </v-img>
              <img
                src="@/assets/body-02.svg"
                class="header-app"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </transition>
      </v-col>
    </v-row>
    <v-dialog
      v-model="sortDialog"
      v-if="sortDialog"
      scrollable
      width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            small
            absolute
            @click="sortDialog = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
          Ordenar banners
        </v-card-title>
        <v-divider> </v-divider>
        <v-card-text>
          <draggable
            v-model="arraySort"
            group="people"
            @start="drag = true"
            @end="drag = false"
          >
            <div
              v-for="element in arraySort"
              :key="element[`.key`]"
              class="grab"
            >
              <div class="list-wrap-img">
                <v-img
                  :src="element.cover.original"
                  :lazy-src="element.cover.loading"
                  aspect-ratio="1"
                  class="grey lighten-2 my-2"
                  width="100px"
                  max-width="150px"
                  height="60px"
                  style="border-radius: 10px"
                >
                </v-img>

                <v-icon color="primary"> fa-solid fa-sort </v-icon>
              </div>

              <!-- <v-divider v-if="index < arraySort.length - 1"></v-divider> -->
            </div>
          </draggable>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="actions-btn">
          <v-btn
            color="primary"
            style="border-radius: 10px"
            @click="SaveSort"
            :loading="btnLoading"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEdit" v-if="dialogEdit" scrollable width="1200px">
      <formNewBanner
        @closed="dialogEdit = false"
        :dataEdit="selectedBanner"
        :selectedCity="selectedCity"
        :selectedCountry="selectedCountry"
      />
    </v-dialog>
    <v-dialog v-model="dialog" v-if="dialog" scrollable width="1200px">
      <formNewBanner
        @closed="dialog = false"
        :selectedCity="selectedCity"
        :selectedCountry="selectedCountry"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogdeleted"
      v-if="dialogdeleted"
      scrollable
      max-width="300px"
    >
      <v-card>
        <v-card-title>
          Eliminar banner
          <v-btn
            icon
            absolute
            right
            top
            color="primary"
            @click="dialogdeleted = false"
          >
            <v-icon small> fa fa-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="my-4">
          <v-img
            :src="deletedSelect.cover.original"
            :lazy-src="deletedSelect.cover.loading"
          ></v-img>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="flex-end">
          <v-btn text height="40" @click="dialogdeleted = false">
            Cancelar
          </v-btn>
          <v-btn
            class="primary"
            height="40"
            @click="deleted(deletedSelect[`.key`])"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import draggable from "vuedraggable";
import { mapActions, mapState } from "vuex";
import headerLayaut from "@/components/header-layaut.vue";
import formNewBanner from "@/components/formNewBanner.vue";
import { db, fb } from "@/firebase";
import objectAssign from "object-assign";
export default {
  name: "BannerApp",

  components: {
    headerLayaut,
    draggable,
    lottie: Lottie,
    formNewBanner,
  },
  mixins: [lottieSettings],
  data() {
    return {
      dialogdeleted: false,
      deletedSelect: null,
      dialog: false,
      selectedBanner: null,
      allBanners: [],
      arraySort: null,
      dialogEdit: null,
      btnLoading: false,
      selectBanner: null,
      cities: [],
      countries: [],
      selectedCity: null,
      selectedCountry: null,
      sortDialog: false,
      loading: true,
      headers: [
        { text: "cover", value: "cover" },
        // { text: "url", value: "url" },
        // { text: "target", value: "target" },
        { text: "Comienza", value: "dateStart" },
        { text: "Termina", value: "dateEnd" },
        { text: "Es Tester", value: "isTester" },
        { text: "Activo", value: "active" },

        { text: "", value: "options" },
      ],
    };
  },
  filters: {
    tsToDate: function (timestamp) {
      // if (!timestamp) return "n/a";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY, hh:mm:ss A ");
    },
    tsToTime: function (timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("HH:mm:ss:SSS");
    },
  },
  computed: {
    ...mapState(["selectedEvent", "user"]),
    showMobile() {
      return this.allBanners.length > 0 ? true : false;
    },
    nameCity() {
      return this.cities.find((e) => e[`.key`] == this.selectedCity);
    },
    sortedBanners() {
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }

      return JSON.parse(JSON.stringify(this.allBanners.sort(compare)));
    },
  },
  methods: {
    ...mapActions(["visibleSearch", "Alert_", "addBusiness", "_searchTerm"]),

    openDialogDeleted(item) {
      this.deletedSelect = item;
      this.dialogdeleted = true;
    },
    openEdit(item) {
      this.selectedBanner = item;
      this.dialogEdit = true;
    },
    deleted(key) {
      db.collection("eventsBanners")
        .doc(key)
        .update({
          deleted: true,
          active: false,
        })
        .then(() => {
          this.dialogdeleted = false;
          this.Alert_({
            text: "El banner fue eliminado correctamente",
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "mdi-check",
            type: "info",
          });
        })
        .catch((error) => {
            this.dialogdeleted = false;
          this.Alert_({
            text: "ocurrio un error",
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "mdi-check",
            type: "error",
          });
        });
    },
    getCities(countryId) {
      this.categories = [];
      this.cities = [];

      this.selectedCity = "";

      db.collection("cities")
        .where("deleted", "==", false)
        .where("countryId", "==", countryId)
        .get()
        .then((response) => {
          response.forEach((item) => {
            let city = item.data();
            city[".key"] = item.id;
            this.cities.push(city);
          });
        });
    },
    async getBanners() {
      this.allBanners = [];
      await this.$binding(
        "allBanners",
        db
          .collection("eventsBanners")
          .where("city", "==", this.selectedCity)
          .where("country", "==", this.selectedCountry)
          .where("deleted", "==", false)
      );
      // this.selectBanner = Object.assign({}, this.sortedBanners[0].cover);
    },
    openSort() {
      // this.arraySort = JSON.parse(JSON.stringify(this.allBanners));
      this.arraySort = Object.assign([], this.allBanners);
      this.sortDialog = true;
    },
    async SaveSort() {
      this.selectBanner = null;
      this.loading = true;
      this.btnLoading = true;

      let batch = db.batch();
     console.log(this.arraySort);

      for (let i = 0; i < this.arraySort.length; i++) {
        const element = this.arraySort[i];
       console.log(element[`.key`]);
        batch.update(db.collection("eventsBanners").doc(element[`.key`]), {
          position: i,
        });
        // await db.collection("eventsBanners").doc(element[`.key`]).update({
        //   position: i,
        // });
      }
      await batch.commit();
      this.loading = false;
      this.Alert_({
        text: "El orden fue guardado",
        timeout: 2000,
        btn_closed: true,
        icon: false,
        iconType: "mdi-check",
        type: "info",
      });
      this.arraySort = [];
      this.btnLoading = false;
      this.sortDialog = false;
      // this.selectBanner = Object.assign({}, this.sortedBanners[0].cover);
    },
    switchControlChanged(data, idDoc, business, field) {
      //console.log(data, idDoc, business, field);
      let prevData = Object.assign({}, data);
      prevData[field] = !data[field];

      if (data && idDoc) {
        data.modifiedAt = new Date();
        data.modifiedBy = this.user[`.key`];
       // console.debug(data);
        db.collection("eventsBanners")
          .doc(idDoc)
          .update(data)
          .then(() => {
            this.Alert_({
              text: "Información del evento actualizada correctamente.",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "#00cdbc",
            });
          })
          .catch((err) => {
           console.log(err);
            this.Alert_({
              text: "Ocurrió un error inesperado, inténtelo nuevamente.",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });

            business[field] = !business[field];
          });
      }
    },
  },
  async mounted() {
    this.loading = false;
    this._searchTerm("banner app");
    this.visibleSearch(true);

    await this.$binding(
      "countries",
      db
        .collection("countries")
        .where("deleted", "==", false)
        .where("active", "==", true)
    );
    this.loading = false;
  },
};
</script>
<style lang="scss" scoped>
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrap-mobile {
  transition: 0.3s ease-in-out;
  width: 280px;
  height: 580px;
  background-color: #090a0d;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: #00cdbc1b 0px 10px 300px 0px;
  &:hover {
    transform: scale(1.1, 1.1);
    box-shadow: #00cdbc3b 0px 10px 300px 0px;
  }
  .display {
    width: calc(280px);
    height: calc(580px);
    border-radius: 40px;
    background-color: #160819;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    align-items: center;
    border: solid 10px #090a0d;
  }
}

.docs-demo-device__ios-notch {
  fill: #090a0d;
  top: -1px;
  width: 165px;
  z-index: 3;
  position: absolute;
}
.header-app {
  width: 100%;
  margin-top: 5px;
  left: 0px;
  border-bottom: rgba(255, 255, 255, 0) solid 1px;
}
.banners-wrap {
  width: 100%;
  height: 150px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.064);
}
.animations {
  transition: 0.1s ease-in-out;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
.grab {
  cursor: grab;
}
.list-wrap-img {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-complete-item {
  transition: all 0.5s ease-in-out;
  display: inline-block;
}
.btn-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 20px;
  position: absolute;
  bottom: 10px;
  .btn-actions {
    cursor: pointer;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    margin: 5px;
    box-shadow: #1608196c 0px 5px 10px 2px;
    transition: 0.3s ease-in-out;
    &:hover {
      transform: scale(1.5, 1.5);
    }
  }
  .btn-actions-active {
    background-color: #00cdbc;
    transform: scale(1.5, 1.5);
  }
}
.name-city {
  font-size: 9px;
  position: absolute;
  z-index: 4;
  top: 46px;
  font-weight: 600;
  left: 65px;
  color: #fff;
}
</style>
